// セクションスクロール用js
// スムーススクロール
const paginations = document.querySelectorAll(".p-pagenation a");
const arrows = document.querySelectorAll(".p-main-section__arrow");
const scrollTarget = Object.setPrototypeOf([...paginations, ...arrows], NodeList.prototype);
scrollTarget.forEach(scrollTarget => {
    scrollTarget.addEventListener("click", e => {
        e.preventDefault();
        const targetId = e.target.hash.slice(1);
        const target = document.getElementById(targetId);
        target.scrollIntoView({ behavior: "smooth" });
    });
});

// Intersection Observer
const sections = document.querySelectorAll(".scroll-section");
const observerRoot = document.querySelector(".p-full-page-scroll");
const options = {
    root: observerRoot,
    rootMargin: "-50% 0px",
    threshold: 0
};
const observer = new IntersectionObserver(doWhenIntersect, options);
sections.forEach(section => {
    observer.observe(section);
});

/**
 * 交差したときに呼び出す関数
 * @param entries - IntersectionObserverEntry IntersectionObserverが交差したときに渡されるオブジェクトです。
 */
function doWhenIntersect(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            if ($(entry.target).hasClass('p-main-section')) {
                activatePagination(entry.target);
                let TargetSection = document.getElementById(activatePagination(entry.target));
                TargetSection.classList.add('scroll-animate-active');
            }
        }
    });
}

/**
 * ページネーションの大きさを変える関数
 * @param element - HTMLElement 現在表示中のスライドのHTML要素を引数に取ります。
 */
function activatePagination(element) {
    if ($(element).hasClass('p-main-section')) {
        const currentActiveIndex = document.querySelector(
            "#pagination .active"
        );
        if (currentActiveIndex !== null) {
            currentActiveIndex.classList.remove("active");
        }
        const newActiveIndex = document.querySelector(
            `a[href='#${element.id}']`
        );
        newActiveIndex.classList.add("active");
        return element.id;
    }

}