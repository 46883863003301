// メニュー用js
// IntersectionObserver オブジェクトを作成
// 交差時に実行するコールバック関数を渡す
if ($('body').attr('id') == 'top') {
    const observer = new IntersectionObserver((entries) => {
        for (const e of entries) {
            if (e.isIntersecting) {
                document.querySelector('.l-header').classList.add('is-in');

            } else {
                document.querySelector('.l-header').classList.remove('is-in');
            }
        }
    });

    observer.observe(document.getElementById('hero'));
} else {
    $('.l-header').removeClass('is-in');
}



$('.l-header__icon').on('click', function() {
    $('.l-header').addClass('active');
});
$('.l-header__icon-close').on('click', function() {
    $('.l-header').removeClass('active');
});