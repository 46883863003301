window.onload = function() {
    if ($('body').attr('id') !== 'top') {
        $(function() {
            $('.l-visible').one('inview', function(event, isInView) {
                if (isInView) {
                    $(this).addClass('l-effect');
                }
            });
        });

    }
}