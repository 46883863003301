if ($('body').attr('id') == 'top') {
    const sectionViewportHeight = window.innerHeight;
    $('.p-main-section').css('height', sectionViewportHeight);
    // トップページ内のjs処理
    $(function() {
        $('.p-others__interview__inner').on('init', function(event, slick) {
                $('.current').text(slick.currentSlide + 1);
                $('.total').text(slick.slideCount);
            })
            .slick({
                dots: false,
                swipe: false,
                prevArrow: '<div class="slide-arrow prev-arrow"></div>',
                nextArrow: '<div class="slide-arrow next-arrow"></div>'
            })
            .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
                $('.current').text(nextSlide + 1);
            });
    });

    const open = document.getElementById('full-movie-btn');
    const mask = document.getElementById('full-movie-mask');
    const modal = document.getElementById('full-movie-modal');
    open.addEventListener('click', function() {
        mask.classList.remove('hidden');
        modal.classList.remove('hidden');
        $('.full-movie-play').get(0).play();
        $('body').css('overflow', 'hidden');
    });
    modal.addEventListener('click', function() {

        if (modal.classList.contains('stop')) {
            $('.full-movie-play').get(0).play();
            modal.classList.remove('stop');
        } else {
            $('.full-movie-play').get(0).pause();
            modal.classList.add('stop');
        }
    });
    mask.addEventListener('click', function() {
        mask.classList.add('hidden');
        modal.classList.add('hidden');
        $('body').css('overflow', 'auto');
        setTimeout(function() {
            $('.full-movie-play').get(0).load();
        }, 400);
    });

    //トップページのみスマホの拡大・縮小を止める
    document.body.addEventListener("touchstart", function(e) {
        if (e.touches && e.touches.length > 1) {
            e.preventDefault();
        }
    }, { passive: false });
    document.body.addEventListener("touchmove", function(e) {
        if (e.touches && e.touches.length > 1) {
            e.preventDefault();
        }
    }, { passive: false });
}