if ($('body').attr('id') == 'top') {
    const target = document.createElement('div');
    target.setAttribute('class', 'l-back__top');
    const main = document.querySelector('main');
    main.insertBefore(target, main.firstChild);

    const backToTop = document.querySelector(".l-footer-top__nav-back");
    backToTop.addEventListener("click", e => {
        e.preventDefault();
        const target = document.querySelector('.l-back__top');
        target.scrollIntoView({ behavior: "smooth" });
    });
} else {
    const backToTop = document.querySelector(".l-footer-top__nav-back");
    backToTop.addEventListener("click", e => {
        $('body, html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
}