if ($('body').attr('id') == 'top') {

    const cookie = document.cookie.indexOf('body');
    const loadingArea = document.querySelector('.l-loading'); // ローディング背景
    const windowSize = window.innerWidth;

    const pcMovie = $(".p-hero__movie-video--pc");
    const spMovie = $(".p-hero__movie-video--sp");
    const movie = windowSize < 641 ? spMovie.get(0) : pcMovie.get(0);
    //cssとjs両方で表示制御を行わないようにする
    const nonplay = windowSize < 641 ? pcMovie : spMovie;
    nonplay.css('display', 'none');

    const inner = document.querySelector('.l-loading__inner');
    if (cookie == -1) {
        inner.style.display = 'block';
        document.cookie = 'name="body"; max-age=60*60*24*7;';
        document.body.classList.add('hidden');
        setTimeout(function() {
            // 画像の読み込み
            var countDownTarget = document.querySelector('.l-loading__percent-num');
            var myObject = {
                prop1: 0
            }
            anime({
                targets: myObject,
                prop1: 100,
                easing: 'linear',
                duration: 3000,
                round: 1,
                update: function() {
                    countDownTarget.innerHTML = JSON.stringify(myObject['prop1']);
                },
                complete: function() {
                    loadingArea.classList.add('hidden');
                    setTimeout(function() {
                        loadingArea.style.display = 'none';
                        movie.play();
                    }, 400);
                }
            });
        }, 100);
    } else {
        loadingArea.classList.add('hidden');
        loadingArea.style.display = 'none';
        setTimeout(function() {
            loadingArea.style.display = 'none';
            movie.play();
        }, 600);
    }
}