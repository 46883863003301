// トップページで使用しているアニメーションの実行
import anime from 'animejs';
$(function() {
    (function($) {
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
        $.fn.attrchange = function(callback) {
            if (MutationObserver) {
                var options = {
                    subtree: false,
                    attributes: true
                };
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(e) {
                        callback.call(e.target, e.attributeName);
                    });
                });
                return this.each(function() {
                    observer.observe(this, options);
                });

            }
        }
    })(jQuery);

    //アニメーション実行前に各sectionのオブジェクトの初期配置を定義し、透明にしておく
    anime({
        targets: '.p-main-section',
        translateY: 0,
        translateZ: 0,
        opacity: 0
    });
    anime({
        targets: '.p-main-section__arrow,.p-main-section__bg,.p-main-section__title,.p-main-section__content-text,.p-main-section .l-btn,.main-section__img',
        translateY: 20,
        translateZ: 0,
        opacity: 0
    });
    anime({
        targets: '.p-main-section__content-img',
        translateX: 10,
        translateZ: 0,
        opacity: 0
    });


    $('.scroll-section').attrchange(function(attrName) {
        if (attrName == 'class') {
            const attrId = $(this).attr('id');
            //anime.jsを使用した各sectionのアニメーションタイムライン
            const Timeline = anime.timeline();
            Timeline
                .add({
                    targets: `#${attrId} .p-main-section__bg`,
                    opacity: 1,
                    easing: 'easeOutSine',
                    complete: function() {
                        $(`#${attrId} .p-main-section__bg-fill svg`).addClass('active');
                        $(`#${attrId} .p-main-section__bg-outline svg`).addClass('active');
                    }
                })
                .add({
                    targets: `#${attrId}`,
                    opacity: 1,
                    duration: 700,
                    translateY: 0,
                    translateZ: 0,
                    easing: 'easeOutSine',
                    complete: function() {

                    }
                })
                .add({
                    targets: `#${attrId} .p-main-section__content-img`,
                    opacity: 1,
                    translateX: 0,
                    translateZ: 0,
                    delay: 0,
                    easing: 'easeOutSine',
                    duration: 700
                })
                .add({
                    targets: `#${attrId} .p-main-section__title,#${attrId} .p-main-section__content-text,#${attrId} .l-btn,#${attrId} .main-section__img,#${attrId} .p-main-section__arrow`,
                    opacity: 1,
                    translateY: 0,
                    translateZ: 0,
                    delay: 0,
                    easing: 'easeOutSine',
                    duration: 700
                })
        }
    });

});